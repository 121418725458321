import React, { useState, useEffect } from "react";
import Axios from "axios";
import { loginRequest } from "../authConfig";
import alok from "../assets/img/alok.png";
import ContactCard from "./Grid/ContactCard";

import OnCall from "./Dialpad/OnCall";

import { useMsal } from "@azure/msal-react";

const Contacts = (props) => {
  const [contactlist, setContactlist] = useState<any[]>([]);
  const [callStatus, setCallStatus] = useState({});
  const [dialContactName, setDialContactName] = useState("");
  const [dialContactNumber, setDialContactNumber] = useState("");
  const [Message, setMessage] = useState("");

  const [showElement, setShowElement] = React.useState(true);

  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  const handleDialClick = async (contactname, phone) => {
    setDialContactName(contactname);
    setDialContactNumber(phone);
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: props.accountemail,
      contact: phone,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
    const mesg = JSON.parse(result.data.message);
    if (mesg.status == "Success") {
      //setCallStatus("Dial Now");
    } else {
      setShowElement(true);
      setMessage(mesg.message);
      setTimeout(function () {
        setShowElement(false);
      }, 5000);
    }
  };
  const handleHungUpClick = () => {
    setCallStatus("Hung Up");
  };

  const { instance, accounts } = useMsal();
  const useraccount = instance.getAllAccounts();
  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          fetchRecentContacts(response.accessToken);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            fetchRecentContacts(response.accessToken);
          });
        });
    }
  }, [accounts, instance]);

  const fetchRecentContacts = async (accessToken: any) => {
    try {
      const graphUrl = "https://graph.microsoft.com/v1.0/me/contacts?$top=10";
      const response = await Axios.get(graphUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setContactlist(response.data.value);
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
  };

  const fetchemail = (jsonobject) => {
    return jsonobject[0].address;
  };
  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          {showElement && Message ? (
            <div className="alert alert-warning">{Message}</div>
          ) : (
            <></>
          )}
          <div className="contact_list">
            <div className="row pt-2">
              {contactlist.map((contact) => (
                <>
                  {contact.mobilePhone ||
                  contact.businessPhones.length > 0 ||
                  contact.homePhones.length > 0 ? (
                    <>
                      {contact.mobilePhone ? (
                        <>
                          <div className="col-12" key={contact.id}>
                            <ContactCard
                              imagePath={alok}
                              contactEmail={fetchemail(contact.emailAddresses)}
                              teamsUserCredential={props.teamsUserCredential}
                              contactName={contact.displayName}
                              contactNumber={contact.mobilePhone}
                              accountemail={props.accountemail}
                              onhandleDialClick={handleDialClick}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.businessPhones.length > 0 ? (
                        <>
                          {contact.businessPhones.map((bphone) => (
                            <>
                              {bphone.trim().length > 0 ? (
                                <>
                                  <div className="col" key={bphone.id}>
                                    <ContactCard
                                      imagePath={alok}
                                      contactEmail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      contactName={contact.displayName}
                                      contactNumber={bphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.homePhones.length > 0 ? (
                        <>
                          {contact.homePhones.map((hphone) => (
                            <>
                              {hphone.trim().length > 0 ? (
                                <>
                                  <div className="col" key={hphone.id}>
                                    <ContactCard
                                      imagePath={alok}
                                      contactemail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      teamsUserCredential={
                                        props.teamsUserCredential
                                      }
                                      contactName={contact.displayName}
                                      contactNumber={hphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <>
        {callStatus == "Dial Now" && (
          <OnCall
            name={dialContactName}
            number={dialContactNumber}
            status={callStatus}
            onhandleHungUpClick={handleHungUpClick}
            accountemail={props.accountemail}
          />
        )}
      </>
    </>
  );
};
export default Contacts;
