import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./lib/config";
import Main from "./Main";
import { TeamsFxContext } from "./Context";
import { msalConfig } from "../authConfig";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize();

const App = () => {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <MsalProvider instance={msalInstance}>
        <Main />
      </MsalProvider>
    </TeamsFxContext.Provider>
  );
};

export default App;
