import logo from "../../assets/img/logo_dark.png";
import { useState, useEffect } from "react";
import Axios from "axios";

const OnCall = (props) => {
  const [dialStatus, setDialStatus] = useState(props.status);
  const [holdStatus, setHoldStatus] = useState("UnHold");
  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });
  const handleClick = async (status) => {
    setDialStatus(status);
    props.onhandleHungUpClick();
    const params = JSON.stringify({
      action: "dialeraction",
      method: "hangup",
      emailaddress: props.accountemail,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
  };
  const handleHoldClick = async () => {
    setHoldStatus("Hold");
    const params = JSON.stringify({
      action: "dialeraction",
      method: "hold",
      emailaddress: props.accountemail,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
  };
  const handleUnHoldClick = async () => {
    setHoldStatus("UnHold");
    const params = JSON.stringify({
      action: "dialeraction",
      method: "unhold",
      emailaddress: props.accountemail,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
  };
  return (
    <>
      {dialStatus === "Dial Now" && (
        <div className="on-call">
          <div className="container d-flex justify-content-center">
            <div className="dialer text-center">
              <div className="logo">
                <img src={logo} alt="logo" className="logo_img mb-3" />
              </div>
              <h6>{props.name}</h6>
              <p>{props.number}</p>
              <p>{holdStatus == "Hold" && <>On Hold</>}</p>
              <div className="mt-3 oncall_btn_section">
                {holdStatus == "UnHold" && (
                  <>
                    <button
                      className="call-button Hold_call back_space_btn mb-3"
                      onClick={() => handleHoldClick()}
                    >
                      <span className="material-symbols-outlined fill">
                        Pause
                      </span>
                    </button>
                    <button
                      className="call-button end_call"
                      onClick={() => handleClick("Hung Up")}
                    >
                      <span className="material-symbols-outlined fill">
                        call_end
                      </span>
                    </button>
                  </>
                )}
                {holdStatus == "Hold" && (
                  <>
                    <button
                      className="call-button Hold_call mb-3"
                      onClick={() => handleUnHoldClick()}
                    >
                      <span className="material-symbols-outlined fill">
                        Pause
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnCall;
