import React from "react";

const InfinityOne = () => {
  return (
    <iframe
      src="https://hqucx.emetrotel.org:21326"
      title="InfinityOne"
      allow="camera;microphone"
    ></iframe>
  );
};

export default InfinityOne;
