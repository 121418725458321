const CallButton = (props) => {
  const handleClick = async (contactname, phone) => {
    props.onhandleDialClick(contactname, phone);
  };

  return (
    <>
      <div
        className="call-icon"
        onClick={() => handleClick(props.contactName, props.phone)}
      >
        <span className="material-symbols-outlined fill">call</span>
      </div>
    </>
  );
};

export default CallButton;
