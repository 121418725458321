import { useContext, useState, useEffect } from "react";
import Configure from "./Configure";
import Axios from "axios";
import { TeamsFxContext } from "./Context";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig } from "../authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import { PublicClientApplication } from "@azure/msal-browser";
import * as msal from "@azure/msal-browser";

import InfinityOne from "./InfinityOne";
import { Tabs } from "rsuite";
import "rsuite/Tabs/styles/index.css";
import Recent from "./Recent";
import Contact from "./Contacts";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const BACKEND_API_TOKEN = process.env.REACT_APP_BACKEND_API_TOKEN;

const validateaccount = async (
  emailaddress,
  backendapiurl,
  backendapitoken
) => {
  const params = JSON.stringify({
    action: "validate",
    emailaddress: emailaddress,
  });
  const headerconfig = {
    headers: {
      "Content-Type": "application/json",
      authorization: backendapitoken,
    },
  };

  const response = await Axios.post(backendapiurl, params, headerconfig);
  return response;
};

const Main = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dialersubscribed, setDialersubscribed] = useState("");
  const [profileMail, setProfileMail] = useState("");
  const [ssoToken, setSsoToken] = useState(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState(isAuthenticated);

  const handleLogin = async () => {
    try {
      await teamsUserCredential!.login([
        "User.Read",
        "Contacts.Read",
        "People.Read",
      ]);
      const accessToken = await teamsUserCredential!.getToken("");
      const userInfo = await teamsUserCredential!.getUserInfo();

      setProfileMail(userInfo.preferredUserName);
      const account = {
        homeAccountId: userInfo.objectId,
        environment: "login.microsoftonline.com",
        tenantId: userInfo.tenantId,
        username: userInfo.preferredUserName,
        localAccountId: userInfo.objectId,
        name: userInfo.displayName,
      };

      await instance.setActiveAccount(account as msal.AccountInfo);

      setIsUserAuthenticated(true);

      setIsLoading(false);

      fetchSubscriptionStatus(account.username);

      /*instance.getTokenCache().loadExternalTokens({
        accessToken: {
          secret: accessToken!.token,
          homeAccountId: userInfo.objectId,
          environment: "login.microsoftonline.com",
          credentialType: "AccessToken",
          clientId: msalConfig.auth.clientId,
          cachedAt: Math.floor(Date.now() / 1000).toString(),
          expiresOn: (Math.floor(Date.now() / 1000) + 3600).toString(), // 1-hour expiry
          realm: userInfo.tenantId,
          target: "User.Read",
        },
      });*/

      /*const loginResponse = await instance.loginPopup({
        scopes: ["User.Read", "Contacts.Read", "People.Read"],
      });*/
    } catch (err) {
      alert("Login failed: " + err);
      return;
    }
  };
  useEffect(() => {
    if (isAuthenticated == true) {
      console.log(accounts[0].username);
      fetchSubscriptionStatus(accounts[0].username);
    }
  });
  const fetchSubscriptionStatus = (email) => {
    if (email !== undefined) {
      if (email != "Null") {
        const subscribed = validateaccount(
          email,
          BACKEND_API_URL,
          BACKEND_API_TOKEN
        );
        subscribed.then(function (result) {
          const res = JSON.parse(result.data.message);
          if (res.status == "Success") {
            setDialersubscribed("Valid");
          } else {
            setDialersubscribed("InValid");
          }
          setIsLoading(false);
        });
      }
    }
  };

  const [timeOut, setTimeOut] = useState(false);

  const HandleSubscription = (status) => {
    setDialersubscribed(status);
    if (timeOut == false) {
      setTimeOut(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setTimeOut(false);
      }, 3000);
    }
  };

  return (
    <>
      {isUserAuthenticated ? (
        <>
          {isLoading ? (
            <div className="contact_bg loader">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="contact_bg">
              {dialersubscribed === "Valid" ? (
                <>
                  <div className="row">
                    <div className="col-2">
                      <Tabs defaultActiveKey="1">
                        <Tabs.Tab eventKey="1" title="Teams Contacts">
                          <Recent accountemail={profileMail} />
                        </Tabs.Tab>
                        <Tabs.Tab eventKey="2" title="Personal Contacts">
                          <Contact accountemail={profileMail} />
                        </Tabs.Tab>
                      </Tabs>
                    </div>
                    <div className="col-10">
                      <InfinityOne />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {dialersubscribed === "InValid" ? (
                <>
                  <Configure
                    onSubscription={HandleSubscription}
                    userEmail={profileMail}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="content_bg">
            <div className="w-100 h-100 text-center pt-5 pb-5">
              <button className="mt-20 btn submit-btn" onClick={handleLogin}>
                SignIn With Microsoft
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Main;
