import { onCLS, onFID, onLCP, onFCP, onTTFB, Metric } from 'web-vitals';

// Custom handler type (optional, for consistent callbacks)
type ReportHandler = (metric: Metric) => void;

// Function to report metrics
const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Register handlers for each Web Vital metric
    onCLS(onPerfEntry);
    onFID(onPerfEntry);
    onLCP(onPerfEntry);
    onFCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};

export default reportWebVitals;