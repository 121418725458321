import { useState, ChangeEvent, FormEvent } from "react";
import Axios from "axios";
import Content from "./Contents";

const Configure = (props) => {
  const [formData, setFormData] = useState({
    subscriptionkey: "",
    serialno: "",
  });

  const [apiData, setApiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const params = JSON.stringify({
      action: "activate",
      subcriptionkey: formData.subscriptionkey,
      emailaddress: props.userEmail,
      serialno: formData.serialno,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken,
      },
    };

    await Axios.post(apiData.apiurl!, params, headerconfig).then(function (
      result
    ) {
      const res = JSON.parse(result.data.message);
      if (res.status == "Success") {
        props.onSubscription("Valid");
      } else {
        props.onSubscription("InValid");
      }
    });
    // Here you can add code to handle form submission, e.g., send the data to a server
  };

  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          <div className="contact_heading">
            <h5>Enter Subscription Details</h5>
          </div>
          <div className="row pt-2">
            <div className="col-6 p-3">
              <form onSubmit={handleSubmit} className="w-100 subscription-form">
                <div className="form-group row mb-3">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="subscriptionkey"
                  >
                    Subscription Key:
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      id="subscriptionkey"
                      name="subscriptionkey"
                      value={formData.subscriptionkey}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label className="col-sm-3 col-form-label" htmlFor="serialno">
                    Serial No:
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      id="serialno"
                      name="serialno"
                      value={formData.serialno}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 text-end">
                    <button
                      type="submit"
                      className="btn btn-success submit-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-6 p-3">
              <Content />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Configure;
