import { useState, ChangeEvent, FormEvent } from "react";
import Axios from "axios";

const Content = () => {
  return (
    <>
      <div className="welcomeText">
        <p>
          Enhance your calling experience by initiating calls through your UCX
          phone system using Microsoft Teams. With the E-MetroTel Infinity
          Dialer, you can dial or click-to-call your contacts from within Teams
          and the call is placed over your office UCX telephone system. A call
          on your existing desktop telephone or softphone will begin
          automatically, enabling you to continue to use the full power of the
          UCX telephony features.
        </p>
        <p>
          To get started, you must be an existing E-MetroTel customer. To learn
          more about E-MetroTel and the Infinity Teams Dialer, visit &nbsp;
          <a href="https://www.emetrotel.com/" target="_blank">
            emetrotel.com
          </a>{" "}
          or review E-MetroTel's&nbsp;
          <a
            href="https://documentation.emetrotel.com/infinity-dialer-for-teams/"
            target="_blank"
          >
            Infinity Dialer
          </a>
          &nbsp; documentation.
        </p>
        <p>
          For support,&nbsp;
          <a href="https://www.emetrotel.com/contact-us/" target="_blank">
            contact us
          </a>
          . When you're there feel free to open an E-MetroTel webchat and choose
          the Support option.
        </p>
      </div>
    </>
  );
};

export default Content;
