import { useState, useEffect } from "react";
import ContactName from "../Grid/ContactName";
import ContactNumber from "../Grid/ContactNumber";
import CallButton from "../Grid/CallButton";

const ContactCard = (props) => {
  const handleDialClick = async (contactname, phone) => {
    props.onhandleDialClick(contactname, phone);
  };

  const [contactImage, setContactImage] = useState("");
  const [needConsent, setNeedConsent] = useState(false);

  const name = props.contactName;

  const firstChar = name.charAt(0);
  const lastChar = name.charAt(name.indexOf(" ") + 1);
  return (
    <>
      <div className="contact-card">
        <div className="profile_name">
          <span>
            {firstChar}
            {lastChar}
          </span>
        </div>
        <div className="pro_info">
          <ContactName name={props.contactName} />
          <ContactNumber phone={props.contactNumber} />
        </div>
        <CallButton
          contactName={props.contactName}
          phone={props.contactNumber}
          accountemail={props.accountemail}
          onhandleDialClick={handleDialClick}
        />
      </div>
    </>
  );
};

export default ContactCard;
