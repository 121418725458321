import React, { useState, useEffect } from "react";
import { useData } from "@microsoft/teamsfx-react";
import Axios from "axios";
import config from "./lib/config";
import * as axios from "axios";
import OnCall from "./Dialpad/OnCall";
import alok from "../assets/img/alok.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactCard from "./Grid/ContactCard";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const Recent = (props) => {
  const [contactlist, setContactlist] = useState<any[]>([]);
  const [callStatus, setCallStatus] = useState({});
  const [dialContactName, setDialContactName] = useState("");
  const [dialContactNumber, setDialContactNumber] = useState("");
  const [ringaction, setRingAction] = useState("Hung Up");
  const [Message, setMessage] = useState("");

  const [showElement, setShowElement] = React.useState(true);

  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  const { instance, accounts } = useMsal();
  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          fetchRecentContacts(response.accessToken);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            fetchRecentContacts(response.accessToken);
          });
        });
    }
  }, [accounts, instance]);

  const fetchRecentContacts = async (accessToken: any) => {
    try {
      const graphUrl = "https://graph.microsoft.com/v1.0/me/people?$top=10";
      const response = await Axios.get(graphUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setContactlist(response.data.value);
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
  };

  const handleDialClick = async (contactname, phone) => {
    setDialContactName(contactname);
    setDialContactNumber(phone);
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: props.accountemail,
      contact: phone,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
    const mesg = JSON.parse(result.data.message);
    if (mesg.status == "Success") {
      //setCallStatus("Dial Now");
    } else {
      setShowElement(true);
      setMessage(mesg.message);
      setTimeout(function () {
        setShowElement(false);
      }, 5000);
    }
  };
  const handleHungUpClick = () => {
    setCallStatus("Hung Up");
  };

  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          {showElement && Message ? (
            <div className="alert alert-warning">{Message}</div>
          ) : (
            <></>
          )}
          <div className="contact_list">
            <div className="row pt-2">
              {contactlist.length > 0 && (
                <>
                  {contactlist.map((contact) => (
                    <>
                      {contact.phones.length > 0 ? (
                        <>
                          {contact.phones.map((phone) => (
                            <>
                              {phone.number.trim().length > 0 ? (
                                <>
                                  <div className="col-12 item" key={phone.id}>
                                    <ContactCard
                                      imagePath={alok}
                                      contactEmail={contact.userPrincipalName}
                                      contactName={contact.displayName}
                                      contactNumber={phone.number}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        {callStatus === "Dial Now" && (
          <OnCall
            name={dialContactName}
            number={dialContactNumber}
            status={callStatus}
            onhandleHungUpClick={handleHungUpClick}
            accountemail={props.accountemail}
          />
        )}
      </>
    </>
  );
};
export default Recent;
