export const msalConfig = {
    auth: {
        clientId: "fbc44597-0718-431f-993d-76168e7805ca",
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: 'https://dialer.emetrotel.net/redirect',
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowRedirectInIframe: true,
    },
};

export const loginRequest = {
    scopes: ["User.Read", "Contacts.Read", "People.Read"]
};
